import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-my-account",
  templateUrl: "./my-account.component.html",
  styleUrls: ["./my-account.component.scss"],
})
export class MyAccountComponent implements OnInit {

  user = { _id: '', rootId: '', name: '', surname: '' };

  public userName: string;
  public profileImg: "assets/images/dashboard/profile.jpg";

  constructor(public router: Router) {
    if (localStorage.getItem("hotel_user")) {
      this.user = JSON.parse(localStorage.getItem("hotel_user"));
      this.userName = this.user.name + ' ' + this.user.surname;
      console.log("true");
    } else {
      console.log("NO ");
    }
  }

  ngOnInit() { }

  logoutFunc() {
    localStorage.clear();
    this.router.navigateByUrl('auth/login');
  }
}
