<div class="container-fluid p-0">
  <div class="row">
    <div class="col-12">
      <div class="login-card">
        <div>
          <div>
            <a class="logo" routerLink="/"><h2>Hotel</h2></a>
          </div>
          <div class="login-main">
            <form class="theme-form" [formGroup]="registrationForm">
              <h4>Einloggen</h4>
              <div class="form-group">
                <label class="col-form-label">Email Adresse</label>
                <input class="form-control" type="email" required="true" placeholder="app@yourmail.com" formControlName="email" />
              </div>
              <div class="form-group">
                <label class="col-form-label">Passwort</label>
                <input class="form-control" [type]="show ? 'text' : 'password'" formControlName="password" required="" placeholder="*********" />
                <div class="show-hide" (click)="showPassword()" *ngIf="!show"><span class="show"></span></div>
                <div class="show-hide" (click)="showPassword()" *ngIf="show"><span class="Hide"></span></div>
              </div>
              <div class="form-group">
                <label class="col-form-label">Vorname</label>
                <input class="form-control" type="text" required="true" placeholder="" formControlName="name" />
              </div>

              <div class="form-group">
                <label class="col-form-label">Nachname</label>
                <input class="form-control" type="text" required="true" placeholder="" formControlName="surname" />
              </div>

              <div class="form-group">
                <label class="col-form-label">Telefon</label>
                <input class="form-control" type="text" required="true" placeholder="" formControlName="phone" />
              </div>

              <div class="form-group mb-0">
                <div class="text-end mt-3">
                  <button class="btn btn-primary d-block w-100" [disabled]="!registrationForm.valid" (click)="registr()" type="button"><span>Einloggen</span></button>
                </div>
              </div>
              
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
