import { Routes } from "@angular/router";

export const content: Routes = [
  {
    path: 'admins',
    loadChildren: () => import("../../components/admins/admins.module").then(m => m.AdminsModule)
  },
  {
    path: 'dates',
    loadChildren: () => import("../../components/dates/dates.module").then(m => m.DatesModule)
  },
  {
    path: 'users',
    loadChildren: () => import("../../components/users/users.module").then(m => m.UsersModule)
  },
  {
    path: 'user/:id',
    loadChildren: () => import("../../components/user/user.module").then(m => m.UserModule)
  },
  {
    path: 'orders',
    loadChildren: () => import("../../components/orders/orders.module").then(m => m.OrdersModule)
  },
  {
    path: 'order/:id',
    loadChildren: () => import("../../components/order/order.module").then(m => m.OrderModule)
  }
];
