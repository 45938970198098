import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { RequestService } from "src/app/services/http-service.service";

@Component({
  selector: "app-registration",
  templateUrl: "./registration.component.html",
  styleUrls: ["./registration.component.scss"],
})
export class RegistrationComponent implements OnInit {
  public newUser = false;
  // public user: firebase.User;
  public registrationForm: FormGroup;
  public show: boolean = false
  public errorMessage: any;

  constructor(private fb: FormBuilder, 
    public router: Router,
    public http: RequestService) {
    this.registrationForm = this.fb.group({
      email: ["", [Validators.required, Validators.email]],
      password: ["", Validators.required],
      name: [""],
      surname: [""],
      phone: [""],
    });
  }

  ngOnInit() {}

  registr() {
    if (this.registrationForm.value["email"] != "" && this.registrationForm.value["password"] != "" &&
    this.registrationForm.value["name"] != "" && this.registrationForm.value["surname"] != "" && 
    this.registrationForm.value["phone"] != "") {
      let user = {
        "email": this.registrationForm.value["email"],
        "password": this.registrationForm.value["password"],
        "name" : this.registrationForm.value["name"],
        "surname" : this.registrationForm.value["surname"],
        "phone" : this.registrationForm.value["phone"],
      };
      this.http.post('auth/registration', user).then((res) => {
        console.log(res);
        localStorage.setItem("hotel_user", JSON.stringify(res.administrator));
        localStorage.setItem("hotel_token", res.token);        
        this.router.navigate(["/login"]);  
      });
    } else {
      alert("Füllen Sie alle Felder aus!");
    }
  }

  showPassword(){
    this.show = !this.show
  }
}
